"use client";

import { Children, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import {
	EffectCoverflow,
	Navigation,
	Pagination,
	Zoom,
	FreeMode,
	Thumbs,
} from "swiper/modules";
import Image from "next/image";
import parse from "html-react-parser";

import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "@/app/components/molecules/SlideShow/DesktopStyles.css";

import slideShowStyles from "@/app/styles/Blocks/BlockSlideShow.module.scss";

import Icon from "../../atoms/Icon/Icon";
import { ImagesNews } from "../../organisms/BlockSlideShow/interfaces/SlideShow.interface";
import { getNameImageType } from "@/app/helpers/image/getTypeNameImage";
import { LoadingSlideShow } from "./LoadingSlideShow";
import { EventData } from "@/app/types/EventData.type";
import ButtonOpen from "../../atoms/ButtonOpen/ButtonOpen";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import { usePathname } from "next/navigation";

const SlideShow = ({
	imagesNews,
	aspectRatio,
	theme,
	blockTitle,
	dataLayer,
	loading,
}: {
	imagesNews: ImagesNews[];
	aspectRatio: string;
	theme: string;
	blockTitle: string;
	dataLayer: EventData;
	loading: "eager" | "lazy";
}) => {
	const [isPreviewOpen, setIsPreviewOpen] = useState(false);
	const [widthOfScreen, setWidthOfScreen] = useState<any>();
	const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
	const [isLoading, setisLoading] = useState(true);
	const typeOfRatio: keyof ImagesNews = getNameImageType(
		"image",
		aspectRatio
	) as keyof ImagesNews;

	const [initialImage, setInitialImage] = useState(0);
	const path = usePathname();
	const maxTitleCharacters = 96;
	const handleOpenZoom = (
		event: React.MouseEvent<HTMLButtonElement>,
		index: number
	) => {
		document.body.classList.add(slideShowStyles["slideShow--blockScroll"]);
		setInitialImage(index);
		setIsPreviewOpen(true);
	};
	const handleClosePreview = () => {
		document.body.classList.remove(slideShowStyles["slideShow--blockScroll"]);
		setIsPreviewOpen(false);
	};

	const handleChange = (ev: any, dataLayer: any) => {
		// TODO content_name revisar
		handleDataLayerOnClick(
			{ ...dataLayer, eventAction: "slide" },
			ev.activeIndex + 1,
			path,
			false
		);
	};
	const totalPreviewImages = imagesNews?.length <= 3 ? imagesNews?.length : 4;

	useEffect(() => {
		if (window) {
			setWidthOfScreen(window.innerWidth);
			setisLoading(false);
		}
	}, [widthOfScreen]);
	const isDark = theme === "dark";
	return (
		<>
			{isLoading && (
				<>
					<LoadingSlideShow aspectRatio={aspectRatio} />
				</>
			)}
			{/* Mobile Screen */}
			{widthOfScreen < 834 && (
				<>
					<Swiper
						navigation={{
							nextEl: "swiper-button-next",
							prevEl: "swiper-button-prev",
						}}
						pagination={{
							clickable: true,
							el: `.${slideShowStyles["slideShow__paginationButtons"]}`,
						}}
						scrollbar={{ draggable: true }}
						modules={[EffectCoverflow, Pagination, Navigation]}
						className={`${slideShowStyles["slideShow__swiperContainerMobile"]}`}
						onSlideChange={(ev) => handleChange(ev, dataLayer)}
						style={
							{
								"--padding-top": `${
									!blockTitle && aspectRatio === "1:1"
										? "16px"
										: !blockTitle && aspectRatio === "16:9"
										? "16px"
										: !blockTitle && aspectRatio === "4:3"
										? "16px"
										: !blockTitle && aspectRatio === "3:4"
										? "16px"
										: ""
								}`,
							} as React.CSSProperties
						}
					>
						{Children.toArray(
							imagesNews.map((news, index) => {
								return (
									<>
										<SwiperSlide key={news.idImage}>
											{
												<figure>
													<div
														className={`${slideShowStyles["slideShow__wrapperImage"]}`}
													>
														<Image
															loading={loading}
															src={news[typeOfRatio]}
															width={500}
															height={500}
															quality={75}
															sizes="(max-width:834px) 100vw, (max-width:1280px)50vw, 30vw"
															alt={news.altImage}
															className={`${slideShowStyles["slideShow__image"]}`}
															style={
																{
																	"--heighImage": `${
																		aspectRatio === "1:1"
																			? "100%"
																			: aspectRatio === "16:9"
																			? "100%"
																			: "100%"
																	}`,
																	"--widthImage": `${
																		aspectRatio === "1:1"
																			? "100%"
																			: aspectRatio === "16:9"
																			? "100%"
																			: "100%"
																	}`,
																	"--aspect-ratio": `${
																		aspectRatio === "1:1"
																			? "1/1"
																			: aspectRatio === "16:9"
																			? "16/9"
																			: aspectRatio === "3:4"
																			? "3/4"
																			: aspectRatio === "4:3"
																			? "4/3"
																			: ""
																	}`,
																} as React.CSSProperties
															}
														/>
														<ButtonOpen
															blockData={{
																...dataLayer,
																contentTitle: "open zoom",
															}}
															cardPosition={index + 1}
															action={(event: any) =>
																handleOpenZoom(event, index)
															}
															className={`${slideShowStyles["slideShow__zoomButton"]}`}
															title={"open zoom"}
														>
															<Icon name="openZoom" />
														</ButtonOpen>
													</div>
													<figcaption
														className={`${slideShowStyles["slideShow__figcaption"]}`}
														style={
															{
																"--text-color": `${
																	theme === "light" ? "#00283b" : "#EDF4F8"
																}`,
															} as React.CSSProperties
														}
													>
														{news.editorialTitle.slice(0, maxTitleCharacters)}
													</figcaption>
												</figure>
											}
										</SwiperSlide>
									</>
								);
							})
						)}
						<div
							className={`${slideShowStyles["slideShow__paginationButtons"]}`}
							style={
								{
									"--swiper-pagination-bullet-inactive-color": `${
										theme === "light" ? "#00283B" : "rgba(255, 255, 255, 0.90)"
									}`,
									"--swiper-pagination-color": `${
										theme === "light" ? "#00283B" : "#fff"
									}`,
								} as React.CSSProperties
							}
						/>
					</Swiper>
				</>
			)}
			{/* Preview */}
			{widthOfScreen < 834 && isPreviewOpen && (
				<div className={`${slideShowStyles["slideShow__preview"]}`}>
					<Icon
						name="closePreview"
						className={`${slideShowStyles["slideShow__closePreviewButton"]}`}
						onClick={handleClosePreview}
					/>

					<Swiper
						zoom={true}
						navigation={{
							nextEl: ".swiper-button-next",
							prevEl: ".swiper-button-prev",
						}}
						pagination={{
							clickable: true,
							el: `.${slideShowStyles["slideShow__paginationButtonsPreview"]}`,
						}}
						modules={[Pagination, Navigation, Zoom]}
						className={` ${slideShowStyles["slideShow__imageTopMobilePreview"]}`}
						style={
							{
								"--swiper-theme-color": "#fff",
								"--top-preview-image-mobile": `${
									aspectRatio === "1:1"
										? "20vh"
										: aspectRatio === "16:9"
										? "25vh"
										: aspectRatio === "4:3"
										? "25vh"
										: aspectRatio === "3:4"
										? "67px"
										: ""
								}`,
							} as React.CSSProperties
						}
						initialSlide={initialImage}
					>
						{Children.toArray(
							imagesNews.map((news) => {
								return (
									<>
										<SwiperSlide key={news?.idImage}>
											{
												<figure
													className={`${slideShowStyles["slideShow__figurePreview"]}`}
												>
													<div
														className={`${slideShowStyles["slideShow__wrapperImage"]} ${slideShowStyles["slideShow__wrapperImagePreview"]} swiper-zoom-container`}
													>
														<Image
															src={news[typeOfRatio]}
															width={500}
															height={500}
															sizes="(max-width:834px) 100vw"
															quality={75}
															alt={news?.altImage}
															className={`${slideShowStyles["slideShow__image"]} ${slideShowStyles["slideShow__previewImage"]}`}
															style={
																{
																	"--heightImage": `${
																		aspectRatio === "1:1"
																			? "100%"
																			: aspectRatio === "16:9"
																			? "100%"
																			: "100%"
																	}`,
																	"--widthImage": `${
																		aspectRatio === "1:1"
																			? "100%"
																			: aspectRatio === "16:9"
																			? "100%"
																			: "100%"
																	}`,
																	"--aspect-ratio": `${
																		aspectRatio === "1:1"
																			? "1/1"
																			: aspectRatio === "16:9"
																			? "16/9"
																			: aspectRatio === "4:3"
																			? "4/3"
																			: aspectRatio === "3:4"
																			? "3/4"
																			: ""
																	}`,
																} as React.CSSProperties
															}
														/>
													</div>
													<figcaption
														className={`${slideShowStyles["slideShow__figcaptionPreview"]}`}
													>
														<h2
															className={`${slideShowStyles["slideShow__editorialTitlePreview"]}`}
														>
															{news.editorialTitle.slice(0, maxTitleCharacters)}
														</h2>
														{/* * Set the div because the parse set a <p></p> Tag */}
														<div
															className={`${slideShowStyles["slideShow__descriptionPreview"]}`}
														>
															{parse(news.description)}
														</div>
													</figcaption>
												</figure>
											}
										</SwiperSlide>
									</>
								);
							})
						)}
						<div
							className={`${slideShowStyles["slideShow__wrapperButtonPreview"]}`}
						>
							<div
								className={`swiper-button-prev ${slideShowStyles["slideShow__previewPrevButton"]}`}
								style={
									{
										"--swiper-navigation-size": "16px",
									} as React.CSSProperties
								}
							/>
							<div
								className={`${slideShowStyles["slideShow__paginationButtonsPreview"]}`}
								style={
									{
										"--swiper-pagination-bullet-inactive-color":
											"rgba(255, 255, 255, 0.40)",
										"--swiper-pagination-color": "#fff",
										"--swiper-pagination-bullet-inactive-opacity": "1",
									} as React.CSSProperties
								}
							/>
							<div
								className={`swiper-button-next ${slideShowStyles["slideShow__previewNextButton"]}`}
								style={
									{
										"--swiper-navigation-size": "16px",
									} as React.CSSProperties
								}
							/>
						</div>
					</Swiper>
				</div>
			)}

			{/* Tablet Screen */}
			{widthOfScreen >= 834 && widthOfScreen < 1280 && (
				<section className={`${slideShowStyles["slideShow__tabletContainer"]}`}>
					<Swiper
						spaceBetween={10}
						thumbs={{ swiper: thumbsSwiper }}
						modules={[FreeMode, Navigation, Thumbs]}
						navigation={{
							nextEl: ".swiper-button-next",
							prevEl: ".swiper-button-prev",
							disabledClass: `${slideShowStyles["slideShow__swiper-button-disabled"]}`,
						}}
						className={`${slideShowStyles["slideShow__swiperContainerTablet"]}`}
						onSlideChange={(ev) => handleChange(ev, dataLayer)}
						style={
							{
								"--padding-top": `${!blockTitle && isDark ? "24px" : "0"}`,
							} as React.CSSProperties
						}
					>
						{Children.toArray(
							imagesNews.map((news, index) => {
								return (
									<>
										<SwiperSlide key={news.idImage}>
											{
												<figure
													className={`${slideShowStyles["slideShow__tabletWrapperInfo"]}`}
													style={
														{
															"--grid-template-columns": `${
																aspectRatio === "1:1"
																	? "minmax(456px, 1fr) 1fr"
																	: aspectRatio === "16:9"
																	? "2fr 1fr"
																	: aspectRatio === "4:3"
																	? "minmax(436px, 1fr) 1fr"
																	: aspectRatio === "3:4"
																	? "minmax(436px, 1fr) 1fr"
																	: ""
															}`,
														} as React.CSSProperties
													}
												>
													<div>
														<div
															className={`${slideShowStyles["slideShow__wrapperImage"]}`}
														>
															<Image
																loading={loading}
																src={news[typeOfRatio]}
																width={500}
																height={500}
																quality={75}
																sizes="(max-width:834px) 100vw"
																alt={news.altImage}
																className={`${slideShowStyles["slideShow__image"]}`}
																style={
																	{
																		"--heighImage": `${
																			aspectRatio === "1:1"
																				? "100%"
																				: aspectRatio === "16:9"
																				? "100%"
																				: "100%"
																		}`,
																		"--widthImage": `${
																			aspectRatio === "1:1"
																				? "100%"
																				: aspectRatio === "16:9"
																				? "100%"
																				: "100%"
																		}`,
																		"--aspect-ratio": `${
																			aspectRatio === "1:1"
																				? "1/1"
																				: aspectRatio === "16:9"
																				? "16/9"
																				: aspectRatio === "3:4"
																				? "3/4"
																				: aspectRatio === "4:3"
																				? "4/3"
																				: ""
																		}`,
																	} as React.CSSProperties
																}
															/>

															<ButtonOpen
																blockData={dataLayer}
																cardPosition={index + 1}
																action={(event: any) =>
																	handleOpenZoom(event, index)
																}
																className={`${slideShowStyles["slideShow__zoomButton"]}`}
																title={"open zoom"}
															>
																<Icon name="openZoom" />
															</ButtonOpen>
														</div>
													</div>

													<figcaption
														className={`${slideShowStyles["slideShow__figcaption"]}`}
													>
														<h2
															className={`${slideShowStyles["slideShow__editorialTitle"]}`}
															style={
																{
																	"--text-color": `${
																		theme === "light" ? "#001621" : "#EDF4F8"
																	}`,
																	"--size-text": `${
																		aspectRatio === "1:1"
																			? "18px"
																			: aspectRatio === "16:9"
																			? "16px"
																			: aspectRatio === "4:3"
																			? "24px"
																			: aspectRatio === "3:4"
																			? "24px"
																			: ""
																	}`,
																	"--line-height-tablet": `${
																		aspectRatio === "1:1"
																			? "24px"
																			: aspectRatio === "16:9"
																			? "22px"
																			: aspectRatio === "4:3"
																			? "28px"
																			: aspectRatio === "3:4"
																			? "28px"
																			: ""
																	}`,
																} as React.CSSProperties
															}
														>
															{news.editorialTitle.slice(0, maxTitleCharacters)}
														</h2>
														{/* * Set the div because the parse set a <p></p> Tag */}
														<div
															className={`${slideShowStyles["slideShow__description"]}`}
															style={
																{
																	"--text-color": `${
																		theme === "light" ? "#001621" : "#EDF4F8"
																	}`,
																	"--size-text": `${
																		aspectRatio === "1:1"
																			? "16px"
																			: aspectRatio === "16:9"
																			? "12px"
																			: aspectRatio === "4:3"
																			? "18px"
																			: aspectRatio === "3:4"
																			? "18px"
																			: ""
																	}`,
																	"--line-height-tablet": `${
																		aspectRatio === "1:1"
																			? "22px"
																			: aspectRatio === "16:9"
																			? "16px"
																			: aspectRatio === "4:3"
																			? "24px"
																			: aspectRatio === "3:4"
																			? "24px"
																			: ""
																	}`,
																	"--anchor-color":
																		theme === "light" ? "#0246CF" : "#8AB1FF",
																} as React.CSSProperties
															}
														>
															{parse(news.description)}
														</div>
													</figcaption>
												</figure>
											}
										</SwiperSlide>
									</>
								);
							})
						)}
						<div>
							<div
								className={`swiper-button-prev ${slideShowStyles["slideShow__swiperPrevTablet"]}`}
								style={
									{
										"--swiper-navigation-size": "16px",
										"--swiper-navigation-sides-offset": "0",
									} as React.CSSProperties
								}
							/>

							<div
								className={`swiper-button-next ${slideShowStyles["slideShow__swiperNextTablet"]}`}
								style={
									{
										"--swiper-navigation-size": "16px",
										"--swiper-navigation-sides-offset": "0",
									} as React.CSSProperties
								}
							/>
						</div>
					</Swiper>
					{/* <Swiper
						onSwiper={setThumbsSwiper}
						spaceBetween={10}
						slidesPerView={totalPreviewImages}
						freeMode={true}
						watchSlidesProgress={true}
						modules={[FreeMode, Navigation, Thumbs]}
						className={`swiperChild`}
					>
						{Children.toArray(
							imagesNews.map((news, index) => {
								return (
									<>
										<SwiperSlide key={news.idImage}>
											{
												<figure
													className={`${slideShowStyles["slideShow__wrapperImagesTablet"]}`}
												>
													<div
														className={`${slideShowStyles["slideShow__wrapperImage"]}`}
													>
														<Image
															loading={loading}
															src={news[typeOfRatio]}
															width={500}
															height={500}
															quality={75}
															sizes="(max-width:834px) 100vw"
															alt={news.altImage}
															className={`${slideShowStyles["slideShow__image"]}`}
															style={
																{
																	"--heighImage": `${
																		aspectRatio === "1:1"
																			? "100%"
																			: aspectRatio === "16:9"
																			? "100%"
																			: "100%"
																	}`,
																	"--widthImage": `${
																		aspectRatio === "1:1"
																			? "100%"
																			: aspectRatio === "16:9"
																			? "100%"
																			: "100%"
																	}`,
																	"--aspect-ratio": `${
																		aspectRatio === "1:1"
																			? "1/1"
																			: aspectRatio === "16:9"
																			? "16/9"
																			: aspectRatio === "3:4"
																			? "3/4"
																			: aspectRatio === "4:3"
																			? "4/3"
																			: ""
																	}`,
																} as React.CSSProperties
															}
														/>
													</div>
												</figure>
											}
										</SwiperSlide>
									</>
								);
							})
						)}
					</Swiper> */}
				</section>
			)}
			{/* Preview Tablet */}
			{widthOfScreen >= 834 && widthOfScreen < 1280 && isPreviewOpen && (
				<div className={`${slideShowStyles["slideShow__preview"]}`}>
					<Icon
						name="closePreview"
						className={`${slideShowStyles["slideShow__closePreviewButton"]}`}
						onClick={handleClosePreview}
					/>

					<Swiper
						zoom={true}
						navigation={{
							nextEl: ".swiper-button-next",
							prevEl: ".swiper-button-prev",
						}}
						pagination={{
							clickable: true,
							el: `.${slideShowStyles["slideShow__paginationButtonsPreview"]}`,
						}}
						modules={[Pagination, Navigation, Zoom]}
						className={`${slideShowStyles["slideShow__imageTopTabletPreview"]}`}
						style={
							{
								"--swiper-theme-color": "#fff",
								"--top-preview-image-tablet": `${
									aspectRatio === "1:1"
										? "96px"
										: aspectRatio === "16:9"
										? "25vh"
										: aspectRatio === "3:4"
										? "81px"
										: aspectRatio === "4:3"
										? "25vh"
										: ""
								}`,
							} as React.CSSProperties
						}
						initialSlide={initialImage}
					>
						{Children.toArray(
							imagesNews.map((news) => {
								return (
									<>
										<SwiperSlide key={news?.idImage}>
											{
												<figure
													className={`${slideShowStyles["slideShow__figurePreview"]}`}
												>
													<div
														className={`${slideShowStyles["slideShow__wrapperImage"]} ${slideShowStyles["slideShow__wrapperImagePreview"]} swiper-zoom-container`}
													>
														<Image
															src={news[typeOfRatio]}
															width={500}
															height={500}
															sizes="(max-width:834px) 100vw"
															quality={75}
															alt={news?.altImage}
															className={`${slideShowStyles["slideShow__image"]} ${slideShowStyles["slideShow__previewImage"]}`}
															style={
																{
																	"--heightImage": `${
																		aspectRatio === "1:1"
																			? "100%"
																			: aspectRatio === "16:9"
																			? "100%"
																			: aspectRatio === "4:3"
																			? "100%"
																			: aspectRatio === "3:4"
																			? "646px"
																			: ""
																	}`,
																	"--widthImage": `${
																		aspectRatio === "1:1"
																			? "100%"
																			: aspectRatio === "16:9"
																			? "100%"
																			: aspectRatio === "4:3"
																			? "100%"
																			: aspectRatio === "3:4"
																			? "646px"
																			: ""
																	}`,
																	"--aspect-ratio": `${
																		aspectRatio === "1:1"
																			? "1/1"
																			: aspectRatio === "16:9"
																			? "16/9"
																			: aspectRatio === "4:3"
																			? "4/3"
																			: aspectRatio === "3:4"
																			? "3/4"
																			: ""
																	}`,
																} as React.CSSProperties
															}
														/>
													</div>
													<figcaption
														className={`${slideShowStyles["slideShow__figcaptionPreview"]}`}
													>
														<h2
															className={`${slideShowStyles["slideShow__editorialTitlePreview"]}`}
														>
															{news.editorialTitle.slice(0, maxTitleCharacters)}
														</h2>
														{/* * Set the div because the "parse" set a <p></p> Tag */}
														<div
															className={`${slideShowStyles["slideShow__descriptionPreview"]}`}
														>
															{parse(news.description)}
														</div>
													</figcaption>
												</figure>
											}
										</SwiperSlide>
									</>
								);
							})
						)}
						<div
							className={`${slideShowStyles["slideShow__wrapperButtonPreview"]}`}
						>
							<div
								className={`swiper-button-prev ${slideShowStyles["slideShow__previewPrevButton"]}`}
								style={
									{
										"--swiper-navigation-size": "16px",
									} as React.CSSProperties
								}
							/>
							<div
								className={`${slideShowStyles["slideShow__paginationButtonsPreview"]}`}
								style={
									{
										"--swiper-pagination-bullet-inactive-color":
											"rgba(255, 255, 255, 0.40)",
										"--swiper-pagination-color": "#fff",
										"--swiper-pagination-bullet-inactive-opacity": "1",
									} as React.CSSProperties
								}
							/>
							<div
								className={`swiper-button-next ${slideShowStyles["slideShow__previewNextButton"]}`}
								style={
									{
										"--swiper-navigation-size": "16px",
									} as React.CSSProperties
								}
							/>
						</div>
					</Swiper>
				</div>
			)}

			{/* Desktop Screen */}
			{widthOfScreen >= 1280 && (
				<section className={`${slideShowStyles["slideShow__tabletContainer"]}`}>
					<Swiper
						spaceBetween={10}
						thumbs={{ swiper: thumbsSwiper }}
						modules={[FreeMode, Navigation, Thumbs]}
						navigation={{
							nextEl: ".swiper-button-next",
							prevEl: ".swiper-button-prev",
							disabledClass: `${slideShowStyles["slideShow__swiper-button-disabled"]}`,
						}}
						className={`${slideShowStyles["slideShow__swiperContainerDestkop"]}`}
						onSlideChange={(ev) => handleChange(ev, dataLayer)}
						style={
							{
								"--padding-top": `${!blockTitle && isDark ? "24px" : "0"}`,
							} as React.CSSProperties
						}
					>
						{Children.toArray(
							imagesNews.map((news, index) => {
								return (
									<>
										<SwiperSlide key={news.idImage}>
											{
												<figure
													className={`${slideShowStyles["slideShow__tabletWrapperInfo"]}`}
													style={
														{
															"--grid-template-columns": `${
																aspectRatio === "1:1"
																	? "minmax(456px, 1fr) 1fr"
																	: aspectRatio === "16:9"
																	? "2fr 1fr"
																	: aspectRatio === "4:3"
																	? "minmax(436px, 1fr) 1fr"
																	: aspectRatio === "3:4"
																	? "minmax(436px, 1fr) 1fr"
																	: ""
															}`,
														} as React.CSSProperties
													}
												>
													<div>
														<div
															className={`${slideShowStyles["slideShow__wrapperImage"]}`}
														>
															<Image
																loading={loading}
																src={news[typeOfRatio]}
																width={500}
																height={500}
																quality={75}
																sizes="(max-width:834px) 100vw"
																alt={news.altImage}
																className={`${slideShowStyles["slideShow__image"]}`}
																style={
																	{
																		"--heighImage": `${
																			aspectRatio === "1:1"
																				? "100%"
																				: aspectRatio === "16:9"
																				? "100%"
																				: "100%"
																		}`,
																		"--widthImage": `${
																			aspectRatio === "1:1"
																				? "100%"
																				: aspectRatio === "16:9"
																				? "100%"
																				: "100%"
																		}`,
																		"--aspect-ratio": `${
																			aspectRatio === "1:1"
																				? "1/1"
																				: aspectRatio === "16:9"
																				? "16/9"
																				: aspectRatio === "3:4"
																				? "3/4"
																				: aspectRatio === "4:3"
																				? "4/3"
																				: ""
																		}`,
																	} as React.CSSProperties
																}
															/>

															<ButtonOpen
																blockData={dataLayer}
																cardPosition={index + 1}
																action={(event: any) =>
																	handleOpenZoom(event, index)
																}
																className={`${slideShowStyles["slideShow__zoomButton"]}`}
																title={"open zoom"}
															>
																<Icon name="openZoom" />
															</ButtonOpen>
														</div>
													</div>

													<figcaption
														className={`${slideShowStyles["slideShow__figcaptionDesktop"]}`}
														style={
															{
																"--padding-container-description": `${
																	aspectRatio === "1:1"
																		? "80px"
																		: aspectRatio === "16:9"
																		? "32px"
																		: aspectRatio === "4:3"
																		? "80px"
																		: aspectRatio === "3:4"
																		? "80px"
																		: ""
																}`,
															} as React.CSSProperties
														}
													>
														<h2
															className={`${slideShowStyles["slideShow__editorialTitleDesktop"]}`}
															style={
																{
																	"--size-desktop": `${
																		aspectRatio === "1:1"
																			? "28px"
																			: aspectRatio === "16:9"
																			? "22px"
																			: aspectRatio === "4:3"
																			? "28px"
																			: aspectRatio === "3:4"
																			? "28px"
																			: ""
																	}`,
																	"--line-height-desktop": `${
																		aspectRatio === "1:1"
																			? "32px"
																			: aspectRatio === "16:9"
																			? "26px"
																			: aspectRatio === "4:3"
																			? "32px"
																			: aspectRatio === "3:4"
																			? "32px"
																			: ""
																	}`,
																	"--text-color": `${
																		theme === "light" ? "#001621" : "#EDF4F8"
																	}`,
																} as React.CSSProperties
															}
														>
															{news.editorialTitle.slice(0, maxTitleCharacters)}
														</h2>
														{/* * Set the div because the parse set a <p></p> Tag */}
														<div
															className={`${slideShowStyles["slideShow__descriptionDesktop"]}`}
															style={
																{
																	"--size-desktop": `${
																		aspectRatio === "1:1"
																			? "22px"
																			: aspectRatio === "16:9"
																			? "16px"
																			: aspectRatio === "4:3"
																			? "22px"
																			: aspectRatio === "3:4"
																			? "22px"
																			: ""
																	}`,
																	"--line-height-desktop": `${
																		aspectRatio === "1:1"
																			? "26px"
																			: aspectRatio === "16:9"
																			? "22px"
																			: aspectRatio === "4:3"
																			? "26px"
																			: aspectRatio === "3:4"
																			? "26px"
																			: ""
																	}`,
																	"--text-color": `${
																		theme === "light" ? "#001621" : "#EDF4F8"
																	}`,
																	"--anchor-color":
																		theme === "light" ? "#0246CF" : "#8AB1FF",
																} as React.CSSProperties
															}
														>
															{parse(news.description)}
														</div>
													</figcaption>
												</figure>
											}
										</SwiperSlide>
									</>
								);
							})
						)}
						<div>
							<div
								className={`swiper-button-prev ${slideShowStyles["slideShow__swiperPrevTablet"]}`}
								style={
									{
										"--swiper-navigation-size": "16px",
										"--swiper-navigation-sides-offset": "0",
									} as React.CSSProperties
								}
							/>

							<div
								className={`swiper-button-next ${slideShowStyles["slideShow__swiperNextTablet"]}`}
								style={
									{
										"--swiper-navigation-size": "16px",
										"--swiper-navigation-sides-offset": "0",
									} as React.CSSProperties
								}
							/>
						</div>
					</Swiper>
					{/* <Swiper
						onSwiper={setThumbsSwiper}
						spaceBetween={10}
						slidesPerView={totalPreviewImages}
						freeMode={true}
						watchSlidesProgress={true}
						modules={[FreeMode, Navigation, Thumbs]}
						className={`swiperChild`}
					>
						{Children.toArray(
							imagesNews.map((news, index) => {
								return (
									<>
										<SwiperSlide key={news.idImage}>
											{
												<figure
													className={`${slideShowStyles["slideShow__wrapperImageDesktopFigure"]}`}
												>
													<div
														className={`${slideShowStyles["slideShow__wrapperImage"]}`}
													>
														<Image
															loading={loading}
															src={news[typeOfRatio]}
															width={500}
															height={500}
															quality={75}
															sizes="(max-width:834px) 100vw"
															alt={news.altImage}
															className={`${slideShowStyles["slideShow__image"]}`}
															style={
																{
																	"--heighImage": `${
																		aspectRatio === "1:1"
																			? "100%"
																			: aspectRatio === "16:9"
																			? "100%"
																			: "100%"
																	}`,
																	"--widthImage": `${
																		aspectRatio === "1:1"
																			? "100%"
																			: aspectRatio === "16:9"
																			? "100%"
																			: "100%"
																	}`,
																	"--aspect-ratio": `${
																		aspectRatio === "1:1"
																			? "1/1"
																			: aspectRatio === "16:9"
																			? "16/9"
																			: aspectRatio === "3:4"
																			? "3/4"
																			: aspectRatio === "4:3"
																			? "4/3"
																			: ""
																	}`,
																} as React.CSSProperties
															}
														/>
													</div>
												</figure>
											}
										</SwiperSlide>
									</>
								);
							})
						)}
					</Swiper> */}
				</section>
			)}
			{/* Preview Desktop */}
			{widthOfScreen >= 1280 && isPreviewOpen && (
				<>
					<div className={`${slideShowStyles["slideShow__preview"]}`}>
						<Icon
							name="closePreview"
							className={`${slideShowStyles["slideShow__closePreviewButton"]}`}
							onClick={handleClosePreview}
						/>

						<Swiper
							zoom={true}
							navigation={{
								nextEl: ".swiper-button-next",
								prevEl: ".swiper-button-prev",
							}}
							pagination={{
								clickable: true,
								el: `.${slideShowStyles["slideShow__paginationButtonsPreview"]}`,
							}}
							modules={[Pagination, Navigation, Zoom]}
							className={`${slideShowStyles["slideShow__imageTopDesktopPreview"]}`}
							style={
								{
									"--swiper-theme-color": "#fff",
									"--top-preview-image-desktop": `${
										aspectRatio === "1:1"
											? "106px"
											: aspectRatio === "16:9"
											? "106px"
											: aspectRatio === "3:4"
											? "106px"
											: aspectRatio === "4:3"
											? "106px"
											: ""
									}`,
								} as React.CSSProperties
							}
							initialSlide={initialImage}
						>
							{Children.toArray(
								imagesNews.map((news) => {
									return (
										<>
											<SwiperSlide key={news?.idImage}>
												{
													<figure
														className={`${slideShowStyles["slideShow__figurePreviewDesktop"]}`}
													>
														<div
															className={`${slideShowStyles["slideShow__wrapperImageDesktop--background"]}`}
														>
															<div
																className={`${slideShowStyles["slideShow__wrapperImageDesktop"]} swiper-zoom-container`}
															>
																<Image
																	src={news[typeOfRatio]}
																	width={500}
																	height={500}
																	quality={75}
																	sizes="(max-width:834px) 100vw"
																	alt={news?.altImage}
																	className={`${slideShowStyles["slideShow__imageDesktop"]} ${slideShowStyles["slideShow__previewImage"]}`}
																	style={
																		{
																			"--heighImage": `${
																				aspectRatio === "1:1"
																					? "100%"
																					: aspectRatio === "16:9"
																					? "100%"
																					: aspectRatio === "4:3"
																					? "100%"
																					: aspectRatio === "3:4"
																					? "100%"
																					: ""
																			}`,
																			"--widthImage": `${
																				aspectRatio === "1:1"
																					? "60%"
																					: aspectRatio === "16:9"
																					? "100%"
																					: aspectRatio === "4:3"
																					? "100%"
																					: aspectRatio === "3:4"
																					? "40%"
																					: ""
																			}`,
																			"--aspect-ratio": `${
																				aspectRatio === "1:1"
																					? "1/1"
																					: aspectRatio === "16:9"
																					? "16/9"
																					: aspectRatio === "4:3"
																					? "4/3"
																					: aspectRatio === "3:4"
																					? "3/4"
																					: ""
																			}`,
																		} as React.CSSProperties
																	}
																/>
															</div>
														</div>
														<figcaption
															className={`${slideShowStyles["slideShow__figcaptionPreviewDesktop"]}`}
														>
															<h2
																className={`${slideShowStyles["slideShow__editorialTitlePreviewDesktop"]}`}
															>
																{news.editorialTitle.slice(
																	0,
																	maxTitleCharacters
																)}
															</h2>
															{/* * Set the div because the "parse" set a <p></p> Tag */}
															<div
																className={`${slideShowStyles["slideShow__descriptionPreviewDesktop"]}`}
															>
																{parse(news.description)}
															</div>
														</figcaption>
													</figure>
												}
											</SwiperSlide>
										</>
									);
								})
							)}
							<div
								className={`${slideShowStyles["slideShow__wrapperButtonPreviewDesktop"]}`}
							>
								<div
									className={`swiper-button-prev ${slideShowStyles["slideShow__previewPrevButton"]}`}
									style={
										{
											"--swiper-navigation-size": "16px",
										} as React.CSSProperties
									}
								/>
								<div
									className={`${slideShowStyles["slideShow__paginationButtonsPreview"]}`}
									style={
										{
											"--swiper-pagination-bullet-inactive-color":
												"rgba(255, 255, 255, 0.40)",
											"--swiper-pagination-color": "#fff",
											"--swiper-pagination-bullet-inactive-opacity": "1",
										} as React.CSSProperties
									}
								/>
								<div
									className={`swiper-button-next ${slideShowStyles["slideShow__previewNextButton"]}`}
									style={
										{
											"--swiper-navigation-size": "16px",
										} as React.CSSProperties
									}
								/>
							</div>
						</Swiper>
					</div>
				</>
			)}
		</>
	);
};

export default SlideShow;
