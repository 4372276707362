import { Children } from "react";
import Image from "next/image";

import mosaico from "@/app/styles/Blocks/BloqueMosaico.module.scss";

import { secondsToMinutes } from "@/app/helpers/timeHelpers/timeHelpers";
import { NewsInfo } from "./interfaces";
import InteractiveLink from "../BloqueHeroGrid/InteractiveLink";

export const MosaicoTwoThree = ({
	newsInfo,
	theme,
	noContent,
	loading,
	blockDataLayer,
}: {
	newsInfo: NewsInfo[];
	theme: string;
	noContent: string;
	loading: "lazy" | "eager";
	blockDataLayer: any;
}) => {
	return (
		<section className={`${mosaico["mosaico-two-three"]}`}>
			{Children.toArray(
				newsInfo.map((news, index) => {
					const typeNews = news.type.split("--")[1];
					return (
						<figure className={`${mosaico["mosaico__figure"]}`}>
							<div className={`${mosaico["mosaico__imageContainer"]}`}>
								<InteractiveLink
									url={news?.path}
									blockData={{
										...blockDataLayer,
										content_name: `${
											typeNews?.toLowerCase() === "news"
												? "NEW>"
												: "VOD>"
										}`,
									}}
									cardPosition={index+1}
									title={news?.title}
								>
									<div>
										{typeNews === "video" ? (
											<>
											<Image
												src={
													news?.thumbnail?.url?.webp || "/assets/placeholder_.svg"
												}
												height={news?.thumbnail?.height ?? 207}
												width={news?.thumbnail?.width ?? 368}
												className={`${mosaico["mosaico__image"]}`}
												alt={
													news?.thumbnail?.alt ||
													"Imagen de portada N+"
												}
												sizes={`${
													noContent === "2"
														? "(min-width: 1440px) 50vw, (min-width:1280px) 35vw, (min-width: 1024px) 23.1vw, (min-width:820px) 48.8vw, (min-width:768px ) 45vw, (min-width: 720px) 38vw, (min-width:540px) 40vw, (min-width: 414px) 35vw, (min-width:390px) 30vw, 49vw"
														: "(min-width: 1440px) 25vw, (min-width:1280px) 15vw, (min-width: 1024px) 23.1vw, (min-width:820px) 48.8vw, (min-width:768px ) 45vw, (min-width:540px) 40vw, (min-width: 414px) 48.2vw, (min-width:390px) 30vw, 49vw"
												}`}
												loading={loading}
											/>
											<div
												className={`${mosaico[`mosaico${noContent}__timeVideoWrapper`]}`}
											>
												<span>
													{secondsToMinutes(news?.duration ?? 0)}
												</span>
												<svg
													focusable="false"
													aria-hidden="true"
													viewBox="0 0 24 24"
													data-testid="PlayArrowSharpIcon"
													style={{ width: "24px", height: "24px" }}
													fill="#fff"
												>
													<path d="M8 5v14l11-7L8 5z" />
												</svg>
											</div>
											</>

										) : (
											<Image
												src={
													news?.thumbnail?.url?.webp || "/assets/placeholder_.svg"
												}
												height={news?.thumbnail?.height ?? 207}
												width={news?.thumbnail?.width ?? 368}
												className={`${mosaico["mosaico__image"]}`}
												alt={
													news?.thumbnail?.alt ||
													"Imagen de portada N+"
												}
												sizes={`${
													noContent === "2"
														? "(min-width: 1440px) 50vw, (min-width:1280px) 35vw, (min-width: 1024px) 23.1vw, (min-width:820px) 48.8vw, (min-width:768px ) 45vw, (min-width: 720px) 38vw, (min-width:540px) 40vw, (min-width: 414px) 35vw, (min-width:390px) 30vw, 49vw"
														: "(min-width: 1440px) 25vw, (min-width:1280px) 15vw, (min-width: 1024px) 23.1vw, (min-width:820px) 48.8vw, (min-width:768px ) 45vw, (min-width:540px) 40vw, (min-width: 414px) 48.2vw, (min-width:390px) 30vw, 49vw"
												}`}
												loading={loading}
											/>
										)}
										
									</div>
								</InteractiveLink>
							</div>
							<figcaption
								className={`${mosaico["mosaico__figcaptionWrapper"]}`}
							>
								<p
									className={`${mosaico[`mosaico${noContent}__tag`]}`}
									style={
										{
											"--mosaicoTagColor": `${
												theme.toLocaleLowerCase() === "light"
													? "#2D5D75"
													: "#BFD1DA"
											}`,
										} as React.CSSProperties
									}
								>
									{news?.topic || ""}
								</p>
								<InteractiveLink
									url={news?.path || "/"}
									title={news?.title || "nmas"}
									aria-label={news?.title || ""}
									blockData={{
										...blockDataLayer,
										descripcionBloque: news?.title || "",
										content_name: `${
											typeNews?.toLowerCase() === "news"
												? "NEW>"
												: "VOD>"
										}`,
									}}
									cardPosition={index+1}
								>
									<p
										className={`${mosaico[`mosaico${noContent}__headLine`]}`}
										title={news?.title || ""}
										style={
											{
												"--mosaicoHeadLineColor": `${
													theme.toLocaleLowerCase() === "light"
														? "#103D53"
														: "#EDF4F8"
												}`,
											} as React.CSSProperties
										}
									>
										{news?.title}
									</p>
								</InteractiveLink>
							</figcaption>
						</figure>
					);
				})
			)}
		</section>
	);
};
