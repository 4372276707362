"use client";
import style from "@/app/styles/BloqueMxM/BloqueMxM.module.scss";
import CardDynamic from "../../molecules/CardDynamic/CardDynamic";
import CardLiveMinuteToMinute from "../../molecules/cards/CardLiveMinuteToMinute/CardLiveMinuteToMinute";
import { useState } from "react";
import InteractiveLink from "@/app/components/organisms/BloqueHeroGrid/InteractiveLink";

export default function BloqueMinutoXMinutoProvider({
	data,
	id,
	heroData,
	isLive,
	blockDataLayer,
}: any) {
	const [maxHeight, setMaxHeight] = useState(510);

	return (
		<>
			<CardLiveMinuteToMinute
				cardData={heroData}
				isLive={isLive}
				setMaxHeight={setMaxHeight}
				blockDataLayer={blockDataLayer}
			/>
			<div
				style={{
					maxHeight: `${maxHeight}px`,
				}}
				className={`${style.mxm__wrapper__accordion} ${
					heroData?.isDark ? style.mxm__dark : ""
				}`}
			>
				<CardDynamic
					id={id}
					isStandalone={false}
					mainContent={data?.getMinuteToMinuteMain}
					blockDataLayer={blockDataLayer}
				/>

				{heroData?.showMxmLink ? (
					<div className={style.mxm__wrapper__show_more}>
						<InteractiveLink
							url={heroData?.link ?? "/"}
							blockData={{
								...blockDataLayer,
								tipoElemento: "LinkText",
								content_name: "NEW>",
							}}
							cardPosition={undefined}
						>
							{heroData?.linkText ?? "Ver más"}{" "}
							<svg
								width="4"
								height="9"
								viewBox="0 0 4 9"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M0.125 8.125L3.875 4.375L0.125 0.625L0.125 8.125Z"
									fill="#474B4D"
								/>
							</svg>
						</InteractiveLink>
					</div>
				) : null}
			</div>
		</>
	);
}
