import { Children } from "react";
import Image from "next/image";

import mosaico from "@/app/styles/Blocks/BloqueMosaico.module.scss";
import InteractiveLink from "../BloqueHeroGrid/InteractiveLink";

import { NewsInfo } from "./interfaces/MosaicoRespone";
import { secondsToMinutes } from "@/app/helpers/timeHelpers/timeHelpers";
import { EventData } from "@/app/types/EventData.type";

export const MosaicoFour = ({
	newsInfo,
	theme,
	noContent,
	loading,
	blockDataLayer,
}: {
	newsInfo: NewsInfo[];
	lineColor: string | null;
	theme: string;
	noContent: string;
	loading: "lazy" | "eager";
	blockDataLayer: EventData;
}) => {
	return (
		<section className={`${mosaico["mosaico4"]}`}>
			{Children.toArray(
				newsInfo?.map((news, index) => {
					const typeNews = news.type.split("--")[1];
					return (
						<figure className={`${mosaico["mosaico__figure"]}`}>
							<div className={`${mosaico["mosaico__imageContainer"]}`}>
								<InteractiveLink
									url={news?.path || "/"}
									blockData={{
										...blockDataLayer,
										content_name: `${
											typeNews?.toLowerCase() === "news" ? "NEW>" : "VOD>"
										}`,
									}}
									cardPosition={index + 1}
									title={news?.title}
								>
									<div>
										{typeNews === "video" ? (
											<>
												<Image
													src={
														news?.thumbnail?.url?.webp ||
														"/assets/placeholder_.svg"
													}
													height={news?.thumbnail?.height ?? 207}
													width={news?.thumbnail?.width ?? 368}
													className={`${mosaico["mosaico__image"]}`}
													alt={
														news?.thumbnail?.alt ||
														"Imagen de portada N+"
													}
													sizes="(min-width:1440px) 13vw, (min-width:1280px) 12vw, (min-width: 1180px) 14vw, (min-width: 1024px) 10vw, (min-width: 768px) 23vw, (min-width: 540px) 21vw, (min-width: 414px) 22vw, (min-width: 375px) 25vw, 27vw"
													loading={loading}
												/>
												<div
													className={`${
														mosaico[`mosaico${noContent}__timeVideoWrapper`]
													}`}
												>
													<span>
														{secondsToMinutes(
															news?.duration ?? 0
														)}
													</span>
													<svg
														focusable="false"
														aria-hidden="true"
														viewBox="0 0 24 24"
														data-testid="PlayArrowSharpIcon"
														style={{ width: "24px", height: "24px" }}
														fill="#fff"
													>
														<path d="M8 5v14l11-7L8 5z" />
													</svg>
												</div>
											</>
										) : (
											<>
												<Image
													src={
														news?.thumbnail?.url?.webp ||
														"/assets/placeholder_.svg"
													}
													height={news?.thumbnail?.height ?? 207}
													width={news?.thumbnail?.width ?? 368}
													className={`${mosaico["mosaico__image"]}`}
													alt={
														news?.thumbnail?.alt ||
														"Imagen de portada N+"
													}
													sizes="(min-width:1440px) 13vw, (min-width:1280px) 12vw, (min-width: 1180px) 14vw, (min-width: 1024px) 10vw, (min-width: 768px) 23vw, (min-width: 540px) 21vw, (min-width: 414px) 22vw, (min-width: 375px) 25vw, 27vw"
													loading={loading}
												/>
											</>
										)}
									</div>
								</InteractiveLink>
							</div>
							<figcaption
								className={`${mosaico["mosaico__figcaptionWrapper"]}`}
							>
								<p
									className={`${mosaico[`mosaico${noContent}__tag`]}`}
									style={
										{
											"--mosaicoTagColor": `${
												theme.toLocaleLowerCase() === "light"
													? "#2D5D75"
													: "#BFD1DA"
											}`,
										} as React.CSSProperties
									}
								>
									{news?.topic || ""}
								</p>
								<InteractiveLink
									url={news?.path || ""}
									title={news?.title || ""}
									blockData={{
										...blockDataLayer,
										content_name: `${
											typeNews?.toLowerCase() === "news" ? "NEW>" : "VOD>"
										}`,
									}}
									cardPosition={index + 1}
								>
									<p
										className={`${mosaico[`mosaico${noContent}__headLine`]}`}
										title={news?.title || ""}
										style={
											{
												"--mosaicoHeadLineColor": `${
													theme.toLocaleLowerCase() === "light"
														? "#103D53"
														: "#EDF4F8"
												}`,
											} as React.CSSProperties
										}
									>
										{news?.title}
									</p>
								</InteractiveLink>
							</figcaption>
						</figure>
					);
				})
			)}
		</section>
	);
};
