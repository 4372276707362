"use client";

import heroGrid from "@/app/styles/Blocks/BloqueHeroGrid.module.scss";

import { NewsInfo } from "./interfaces/HeroGridResponse";
import { ToggleProvider } from "@/app/context/ToggleContext";
import { BlockDataType } from "@/app/types/BlockDataType";

import Cards from "../../molecules/HeroGrid/Cards";
import ButtonLoadMore from "../../atoms/ButtonLoadMore/ButtonLoadMore";

export const HeroGridCards = ({
	newsInfo,
	theme,
	noContent,
	loading,
	blockData,
}: {
	newsInfo: NewsInfo[];
	theme: string;
	noContent: string;
	loading: "eager" | "lazy";
	blockData: BlockDataType;
}) => {
	return (
		<ToggleProvider>
			<Cards
				newsInfo={newsInfo}
				theme={theme}
				noContent={noContent}
				loading={loading}
				blockData={blockData}
			/>
			{noContent !== "5" && noContent !== "3" ? (
				<div className={`${heroGrid[`heroGrid${noContent}__wrapperButton`]}`}>
					<ButtonLoadMore noContent={noContent} />
				</div>
			) : (
				<></>
			)}
		</ToggleProvider>
	);
};
