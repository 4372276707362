import heroGrid from "@/app/styles/Blocks/BloqueHeroGrid.module.scss";
import Image from "next/image";
import { NewsInfo } from "../../organisms/BloqueHeroGrid/interfaces/HeroGridResponse";
type SCIType = {
	news: NewsInfo;
	loading: "eager" | "lazy";
	noContent: string;
};
export default function SingleCardImage({ news, loading, noContent }: SCIType) {
	let imageSrc: string;
	const defaultImage: string = "/assets/placeholder_.svg";
	if (noContent === "7" || noContent === "9") {
		imageSrc = news?.image_4_3_desktop || defaultImage;
	} else {
		imageSrc = news?.image_16_9_desktop || defaultImage;
	}

	return (
		<>
			<Image
				src={imageSrc}
				height={25}
				width={50}
				className={`${heroGrid[`heroGrid${noContent}__relations__image`]}`}
				alt={news.altImage || news.editorialTitle || news.title || ""}
				sizes="(min-width: 1440px) 30vw, (min-width: 1280px) 30vw, (min-width: 1024px) 20vw, (min-width: 720px) 18vw, (min-width: 540px) 14vw, (min-width:414px) 15vw, 30vw"
				loading={loading}
			/>
		</>
	);
}
