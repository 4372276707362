import styles from "@/app/styles/Blocks/BloqueHero.module.scss";

import { HeroBlock } from "@/app/types";
import { BlockPropsStructuredData } from "@/app/types/BlockProps.type";
import { upperCamelCase } from "../../../helpers/upperCamelCase/upperCamelCase";
import { secondsToMinutes } from "@/app/helpers/timeHelpers/timeHelpers";
import SendDataLayer from "@/app/hooks/analytics/SendDataLayer";
import WidgetTitle from "../../molecules/WidgetTitle/WidgetTitle";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetHero from "@/app/queries/getHero";
import TapToplayCoverBig from "@/app/components/molecules/TapToPlayCoverBig/TapToPlayCoverBig";
import VideoDuration from "../../atoms/VideoDuration/VideoDuration";
import InteractiveLink from "../BloqueHeroGrid/InteractiveLink";
import ClientImage from "../../atoms/ClientImage/ClientImage";
import CardYoutube from "../../molecules/cards/CardYoutube/CardYoutube";
import ListItem from "@/app/site-tools/structured-data/ListItem";

export const BloqueHero = async ({
	type,
	id,
	position = 0,
	total,
	enabledStructureData = false,
}: BlockPropsStructuredData) => {
	const { data, ...rest } = await fetchApolloData(GetHero, {
		openingBlockId: id,
	});

	if (rest?.errors) {
		console.error(rest?.errors);
	}

	const blockData: HeroBlock = data?.getHero ?? null;
	const blockTypeLayer = type
		.replace("block_content--", "")
		.split("_")
		.join(" ");

	const blockDataLayer = {
		clickBloque: 0,
		content_name: blockData?.content?.data?.isVideo
			? "VOD>"
			: blockData?.content?.data?.isLiveblog
			? "liveblog>"
			: "NEWS>",
		colorBloque: `${blockData?.isDark ? "dark" : "light"}`,
		contenidosBloque: `${
			blockData?.configurationType === "Editorial" ? "2" : "1"
		} contenidos`,
		countWidget: total,
		descripcionBloque: blockData?.cmsName,
		estiloBloque: "16:9",
		event: "select_content",
		eventAction: "click",
		posicionBloque: position ? position + 1 : 0,
		subBloque: upperCamelCase(blockData?.configurationType),
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockTypeLayer),
		tipoElemento: "widget",
		tituloBloque: blockData?.blockTitle,
		contentTitle: "",
	};

	const blockType = blockData?.configurationType.toLowerCase().replace(" ", "");
	const isDark = blockData?.isDark;
	const hasTitle = blockData?.hasTitle;

	const post = blockData?.content?.data;
	blockDataLayer.contentTitle = post?.title;
	const postDuration = post?.duration ?? 0;
	const postDurationSeparator = "|";
	const postDate = new Date(post?.date);
	const postDateMonth = postDate.toLocaleString("es-MX", { month: "long" });
	const postDateString =
		postDateMonth.charAt(0).toUpperCase() +
		postDateMonth.slice(1) +
		" " +
		postDate.toLocaleString("es-MX", { day: "numeric" }) +
		", " +
		postDate.toLocaleString("es-MX", { year: "numeric" });

	const isBreaking = blockData?.content?.isBreaking;
	const hasSummary = blockData?.content?.hasSummary;
	const isVideo = post?.isVideo;
	const isTapToPlay = blockData?.content.isTapToPlay;
	const hasTerms = post?.term.length > 0;

	let isFirstFold = false;

	if (position === 0 && blockType === "hero2") {
		isFirstFold = true;
	}

	const videoData = {
		blocktitle: blockData?.liveAttr.title,
		blockSummary: blockData?.liveAttr.description,
		youtubeID: blockData?.liveAttr.code,
	};
	const IS_VALID =
		enabledStructureData && blockType.toLowerCase() !== "singleshow";
	return (
		<section
			className={
				blockType?.toLowerCase() === "singleshow" && isDark
					? `${styles["hero--dark"]} ${styles["hero__singleShow"]}`
					: blockType?.toLowerCase() === "singleshow"
					? `${styles["hero__singleShow"]}`
					: blockType?.toLowerCase() === "hero2" && isDark && isFirstFold
					? `${styles["hero--dark"]} ${styles["hero__hero2"]} ${styles["hero__firstfold"]}`
					: blockType?.toLowerCase() === "hero2" && isDark
					? `${styles["hero--dark"]} ${styles["hero__hero2"]}`
					: blockType?.toLowerCase() === "hero2"
					? `${styles["hero__hero2"]}`
					: blockType?.toLowerCase() === "editorial" && isDark
					? `${styles["hero--dark"]} ${styles["hero__editorial"]}`
					: blockType?.toLowerCase() === "editorial"
					? `${styles["hero__editorial"]}`
					: ""
			}
		>
			<div
				className={
					isFirstFold
						? `${styles?.hero__inner_wrapper} ${styles?.hero__firstfold_wrapper}`
						: `${styles?.hero__inner_wrapper}`
				}
			>
				{hasTitle && (
					<WidgetTitle
						title={blockData?.blockTitle}
						text={blockData?.blockDescription}
						lineColor={blockData?.borderColor}
						isDark={isDark}
					/>
				)}
				{blockType === "singleshow" && (
					<section
						className={
							hasTitle
								? `${styles?.hero_with_title} ${styles?.hero_show}`
								: `${styles?.hero_show}`
						}
					>
						<CardYoutube data={videoData} />
						<p>{blockData?.liveAttr.description}</p>
					</section>
				)}
				{blockType === "editorial" && (
					<section
						className={
							hasTitle
								? `${styles?.hero_with_title} ${styles?.hero_editorial}`
								: `${styles?.hero_editorial}`
						}
					>
						{isTapToPlay && isVideo && (
							<figure
								id={post?.id}
								className={styles?.hero_card_video}
								style={
									{
										"--playIcon": `${
											isTapToPlay
												? ""
												: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv' fill='%23ffffff' focusable='false' aria-hidden='true' viewBox='0 0 24 24' data-testid='PlayArrowSharpIcon'%3E%3Cpath d='M8 5v14l11-7L8 5z'%3E%3C/path%3E%3C/svg%3E")`
										}`,
									} as React.CSSProperties
								}
							>
								<SendDataLayer blockData={blockDataLayer} cardPosition={1}>
									<TapToplayCoverBig
										cover={post}
										hero={false}
										isTapToPlay={isTapToPlay}
										priority={
											position === 0 || position === 1 || position === 2
										}
									/>
								</SendDataLayer>
								<figcaption>
									{isBreaking && (
										<span className={styles?.hero_breaking}>
											{blockData?.content.textBreaking}
										</span>
									)}
									{!isBreaking && (
										<span
											className={
												isDark
													? `${styles?.hero_tagDark}`
													: `${styles?.hero_tag}`
											}
										>
											{post?.term?.[0]?.name}
										</span>
									)}
									<InteractiveLink
										blockData={blockDataLayer}
										url={post?.path || "/"}
										cardPosition={1}
									>
										<h3>{post?.title}</h3>
									</InteractiveLink>
									<InteractiveLink
										blockData={blockDataLayer}
										cardPosition={1}
										url={post?.path || "/"}
									>
										<p
											className={`${styles["hero_editorial__summary"]}`}
											style={
												{
													color: `${isDark ? "#EDF4F8" : "#474B4D"}`,
												} as React.CSSProperties
											}
										>
											{post?.summary}
										</p>
									</InteractiveLink>
								</figcaption>
							</figure>
						)}
						{!isTapToPlay && (
							<figure id={post?.id}>
								<div className={styles?.fig_card_vod_img_wrapper}>
									<InteractiveLink
										blockData={blockDataLayer}
										url={post?.path ?? "/"}
										title={post?.title}
										cardPosition={1}
									>
										<ClientImage
											priority={position === 0}
											thumbnail={post?.thumbnail}
											customsizes="(min-width: 420px) 100vw, (min-width: 768px) 100vw, (min-width:1024px) 100vw, (min-width: 1200px) 100vw"
										/>
										{post?.isLiveblog && post?.status && (
											<div
												className={`${
													styles[`hero__liveblog__editorial__caption--on`]
												}`}
											>
												<div
													className={`${
														styles[`hero__liveblog__editorial__captionText`]
													}`}
												>
													LIVEBLOG
												</div>
												<div
													className={`${
														styles[`hero__liveblog__editorial__pulseIcon`]
													}`}
												/>
											</div>
										)}
									</InteractiveLink>
									{isVideo && (
										<VideoDuration duration={post?.duration} isPlay={false} />
									)}
								</div>
								<figcaption>
									{isBreaking && (
										<span className={styles?.hero_breaking}>
											{blockData?.content.textBreaking}
										</span>
									)}
									{!isBreaking && (
										<span
											className={
												isDark
													? `${styles?.hero_tagDark}`
													: `${styles?.hero_tag}`
											}
										>
											{post?.term?.[0]?.name}
										</span>
									)}
									<InteractiveLink
										url={post?.path ?? "/"}
										title={post?.title}
										cardPosition={1}
										blockData={blockDataLayer}
									>
										<h3>{post?.title}</h3>
									</InteractiveLink>

									<InteractiveLink
										url={post?.path ?? "/"}
										title={post?.title}
										cardPosition={1}
										blockData={blockDataLayer}
									>
										<p
											style={
												{
													color: `${isDark ? "#EDF4F8" : "#474B4D"}`,
												} as React.CSSProperties
											}
										>
											{post?.summary}
										</p>
									</InteractiveLink>
								</figcaption>
							</figure>
						)}
					</section>
				)}
				{blockType === "hero2" && (
					<section
						className={
							hasTitle && isFirstFold
								? `${styles?.hero_with_title} ${styles?.hero_hero2} ${styles?.hero_hero2_first}`
								: hasTitle && !isFirstFold
								? `${styles?.hero_with_title} ${styles?.hero_hero2}`
								: !hasTitle && isFirstFold
								? `${styles?.hero_hero2_first} ${styles?.hero_hero2}`
								: `${styles?.hero_hero2}`
						}
					>
						{isTapToPlay && isVideo && (
							<figure id={post?.id} className={styles?.hero_card}>
								<SendDataLayer blockData={blockDataLayer} cardPosition={1}>
									<TapToplayCoverBig
										cover={post}
										hero={true}
										priority={
											position === 0 || position === 1 || position === 2
										}
										first={true}
									/>
								</SendDataLayer>
								<figcaption>
									<div>
										{isBreaking && (
											<span className={styles?.hero_breaking}>
												{blockData?.content.textBreaking}
											</span>
										)}
										{!isBreaking && <span>{post?.term?.[0]?.name}</span>}
										<h3>{post?.title}</h3>
										{hasSummary && (
											<p
												className={`${styles["hero_hero2__summary"]}`}
												style={
													{
														"--text-color": `${isDark ? "#EDF4F8" : "#001621"}`,
													} as React.CSSProperties
												}
											>
												{post?.summary}
											</p>
										)}
										<p className={styles?.hero_info}>
											{postDateString}{" "}
											{isVideo && <span>{postDurationSeparator}</span>}{" "}
											{isVideo && (
												<span>
													<b>{"Duración: "}</b>
													<time>{secondsToMinutes(postDuration)}</time>
													&nbsp;mins
												</span>
											)}
										</p>
									</div>
								</figcaption>
							</figure>
						)}
						{!isTapToPlay && (
							<figure id={post?.id} className={styles?.hero_card_new}>
								<div className={styles?.hero_hero2_div}>
									<InteractiveLink
										url={post?.path ?? "/"}
										title={post?.title}
										blockData={blockDataLayer}
										cardPosition={1}
									>
										<ClientImage
											priority={position === 0}
											thumbnail={post?.thumbnail}
											customsizes="(min-width: 420px) 100vw, (min-width: 768px) 100vw, (min-width:1024px) 100vw, (min-width: 1200px) 100vw"
										/>
										{post?.isLiveblog && post?.status && (
											<div
												className={`${
													styles[`hero__liveblog__hero2__mobile__caption--on`]
												}`}
											>
												<div
													className={`${
														styles[`hero__liveblog__hero2__mobile__captionText`]
													}`}
												>
													LIVEBLOG
												</div>
												<div
													className={`${
														styles[`hero__liveblog__hero2__mobile__pulseIcon`]
													}`}
												/>
											</div>
										)}
									</InteractiveLink>
								</div>
								<figcaption
									style={{
										paddingBottom:
											isDark && isFirstFold
												? "0"
												: !isDark && !isFirstFold
												? "24px"
												: isDark && !isFirstFold
												? "0"
												: "0",
									}}
								>
									<div>
										{isBreaking && !post?.isLiveblog && (
											<span className={styles?.hero_breaking}>
												{blockData?.content.textBreaking}
											</span>
										)}
										{post?.isLiveblog && post?.status && (
											<>
												<div
													className={`${styles["hero__liveblog__hero2__wrapperTerm"]} ${styles["hero__liveblog__hero2__desktop"]}`}
												>
													<div
														className={`${
															styles[`hero__liveblog__hero2__caption--on`]
														}`}
													>
														<div
															className={`${
																styles[`hero__liveblog__hero2__captionText`]
															}`}
														>
															LIVEBLOG
														</div>
														<div
															className={`${
																styles[`hero__liveblog__hero2__pulseIcon`]
															}`}
														/>
													</div>
													<span className={`${styles["hero__liveblog__term"]}`}>
														{post?.term?.[0]?.name}
													</span>
												</div>
												<span
													className={`${styles["hero__liveblog__hero2__mobile__term"]}`}
												>
													{post?.term?.[0]?.name}
												</span>
											</>
										)}

										{!isBreaking && hasTerms && !post?.isLiveblog && (
											<span>{post?.term?.[0]?.name}</span>
										)}
										<h3>
											<InteractiveLink
												url={post?.path ?? "/"}
												title={post?.title}
												blockData={blockDataLayer}
											>
												{post?.title}
											</InteractiveLink>
										</h3>
										{hasSummary && <p>{post?.summary}</p>}
										<p className={styles?.hero_info}>
											<InteractiveLink
												url={post?.path ?? "/"}
												title={post?.title}
												blockData={blockDataLayer}
											>
												{postDateString}{" "}
												{isVideo && <span>{postDurationSeparator}</span>}{" "}
												{isVideo && (
													<span>
														<b>{"Duración: "}</b>
														<time>{secondsToMinutes(postDuration)}</time>
														&nbsp;mins
													</span>
												)}
											</InteractiveLink>
										</p>
									</div>
								</figcaption>
							</figure>
						)}
					</section>
				)}
			</div>
			{IS_VALID ? ListItem([post], true) : ""}
		</section>
	);
};
export default BloqueHero;
